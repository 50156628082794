import { browser } from '$app/environment';
import type Keycloak from 'keycloak-js';
import { PUBLIC_KEYCLOAK_CLIENT_ID, PUBLIC_KEYCLOAK_URL, PUBLIC_KEYCLOAK_REALM } from '$env/static/public';

let keycloak: Keycloak | null = null;

if (browser) {
	const KeycloakConstructor = (await import('keycloak-js')).default;
	keycloak = new KeycloakConstructor({
		url: PUBLIC_KEYCLOAK_URL,
		realm: PUBLIC_KEYCLOAK_REALM,
		clientId: PUBLIC_KEYCLOAK_CLIENT_ID
	});
}

export default keycloak;